import './MenuBar.css'

import logo from '../../images/logo.png';
import { ReactComponent as Widgets } from '../../images/widgets.svg';
import { ReactComponent as Overview } from '../../images/overview.svg';
import { ReactComponent as Pending } from '../../images/pending.svg';
import { ReactComponent as Uploaded } from '../../images/uploaded.svg';
import { ReactComponent as Rss } from '../../images/rss.svg';
import { ReactComponent as Assessment } from '../../images/assessment.svg';
import { ReactComponent as Search } from '../../images/search.svg';
import { ReactComponent as Textsms } from '../../images/textsms.svg';
import { ReactComponent as Setting } from '../../images/setting.svg';
import { ReactComponent as Help } from '../../images/help.svg';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[3],
        fontSize: 15,

    },
}));


function MenuBar(props) {
    const { color, setColor, translation } = props;
    const [checked, setChecked] = useState(true);

    const handleChecked = () => {
        setChecked((prev) => !prev);
        // setColor(color === 'light' ? 'dark' : 'light');
    };
    

    return (
        <div className={`MenuBar ${color}`} style={{height: '100vh', zIndex: 10}}>
            <img src={logo} className='logo' alt='logo'/>
            <LightTooltip title={translation.Overview.title} placement='right'>
                <Link to='/overview' id='overview' className={`menu ${color}`}><Overview className='menuIcon'/></Link>
            </LightTooltip>
            <LightTooltip title={translation.Pending.title} placement='right'>
                <Link to='/pending' id='pending' className={`menu ${color}`}><Pending className='menuIcon'/></Link>
            </LightTooltip>
            <LightTooltip title={translation.Records.title} placement='right'>
                <Link to='/uploaded' id='uploaded' className={`menu ${color}`}><Uploaded className='menuIcon'/></Link>
            </LightTooltip>
            <LightTooltip title={translation.RealTime.title} placement='right'>
                <Link to='/realtime' id='realtime' className={`menu ${color}`}><Rss className='menuIcon'/></Link>
            </LightTooltip>
            <LightTooltip title={translation.Information.title} placement='right'>
                <Link to='/setting' id='setting' className={`menu ${color}`}><Setting className='menuIcon'/></Link>
            </LightTooltip>
            <LightTooltip title={translation.Help.title} placement='right'>
                <Link to='https://www.gocochain.com/help' id='help' className={`menu ${color}`} target="_blank"><Help className='menuIcon'/></Link>
            </LightTooltip>
            {/* <Link to='/assessment' id='assessment' className={`menu ${color}`}><Assessment className='menuIcon'/></Link> */}
            {/* <Link to='/textsms' id='textsms' className={`menu ${color}`}><Textsms className='menuIcon'/></Link> */}
            {/* <button id='search' className={`menu ${color}`}><Search className='menuIcon'/></button> */}
            {/* <Switch id='switch' checked={checked} onChange={handleChecked} sx={{marginTop: '100px', marginBottom: '10px'}}/> */}
            {/* <button id='setting' className={`menu ${color}`}><Setting className='menuIcon'/></button> */}
            {/* <button id='help' className={`menu ${color}`}><Help className='menuIcon'/></button> */}

        </div>
    );
}

export default MenuBar;

// <object className='widgets' type='image/svg+xml' data={widgets} alt='widgets' fill={'white'}/>