import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Rss } from '../images/real-time-rss.svg';
import { ReactComponent as Settings } from '../images/real-time-settings.svg';
import { ReactComponent as AddIcon } from '../images/add.svg';
import { ReactComponent as ArrowLeft } from '../images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../images/arrow-right.svg';
import EditIcon from '@mui/icons-material/Edit';
import { 
    Alert, Box, Button, Modal, Snackbar, TextField, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getRealTime, updateRealtime, getRealTimeById } from '../api';
import { convertToCelsius, convertTemperature, convertToSecond, secondToTime } from '../lib/convert.js';
import { temperatureContext } from '../lib/context.js';

import Plot from 'react-plotly.js';

import dayjs from 'dayjs';

import Add from './components/Add.js';
import Edit from './components/Edit.js';


function TempGraph(props) {
    const { detail, record, translation } = props;

    const normalDrawX = detail.length > 432 ? detail.filter((item) => item.temperature <= record.upperLimit && item.temperature >= record.lowerLimit).filter((item, index) => index % 6 === 0).map((item) => item.time) : detail.filter((item) => item.temperature <= record.upperLimit && item.temperature >= record.lowerLimit).map((item) => item.time);
    const normalDrawY = detail.length > 432 ? detail.filter((item) => item.temperature <= record.upperLimit && item.temperature >= record.lowerLimit).filter((item, index) => index % 6 === 0).map((item) => item.temperature) : detail.filter((item) => item.temperature <= record.upperLimit && item.temperature >= record.lowerLimit).map((item) => item.temperature);

    return (
        <>
            <Plot
                data={[
                    {
                        x: detail.map((item) => item.time),
                        y: detail.map((item) => item.temperature),
                        type: 'scatter',
                        mode: 'lines',
                        marker: {color: '#E6E6FF'}, //#e6e6ff
                        showlegend: false,
                    },
                    {
                        x: normalDrawX,
                        y: normalDrawY,
                        type: 'scatter',
                        mode: 'markers',
                        marker: {color: '#3F8EE8'},
                        name: translation.normal,
                    },
                    {
                        x: detail.filter((item) => item.temperature > record.upperLimit || item.temperature < record.lowerLimit).map((item) => item.time),
                        y: detail.filter((item) => item.temperature > record.upperLimit || item.temperature < record.lowerLimit).map((item) => item.temperature),
                        type: 'scatter',
                        mode: 'markers',
                        marker: {color: '#FF3838'},
                        name: translation.abnormal,
                    },
                    // {type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
                ]}
                // layout={ {yaxis: {range: [minTemp-5, maxTemp+5], fixedrange: true}, xaxis: {fixedrange: true}, width: 500, height: 550, title: translation.trendGraph, paper_bgcolor: bgColor, plot_bgcolor: bgColor} }
                config={ {displayModeBar: false} }
            />
        </>
    )
}

function TempList(props) {
    const { record, detail, translation } = props;

    const tempUnit = useContext(temperatureContext);

    // const columns = [
    //     { id: 'time', label: translation.time, minWidth: 60 },
    //     { id: 'temperature', label: translation.temp, minWidth: 60 },
    // ];

    const columns = [
        { id: 'time', label: 'time', minWidth: 60 },
        { id: 'temperature', label: 'temperature', minWidth: 60 },
    ];

    return (
        <div style={{width: '90%'}}>
            <div style={{fontSize: '16px', textAlign: 'center', margin: '20px auto'}}>{'Teperature Records'}</div>
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{bgcolor: '#E0EDFB'}}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {detail.map((row) => {
                            // console.log(row)
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.time}>
                                    {columns.map((column, index) => {
                                        const value = row[column.id];
                                        if (index === 1) {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {/* {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : `${value}°C`} */}
                                                        {(value !== null && value !== "") ? `${value}${tempUnit === 'fahrenheit' ? '°F' : '°C'}` : ''}
                                                </TableCell>
                                            )
                                        }
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : `${value}`}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

function Config(props) {
    const { record, translation } = props;
    console.log(record)

    const tempUnit = useContext(temperatureContext);

    const thresholdConvert = secondToTime(record.timeLimit);
    const intervalConvert = secondToTime(record.interval);

    const [saveAlert, setSaveAlert] = useState(false);

    const [rangeType, setRangeType] = React.useState(record.thresholdType);
    const [upperLimit, setUpperLimit] = React.useState(30);
    const [upperUnit, setUpperUnit] = React.useState(tempUnit);
    const [lowerLimit, setLowerLimit] = React.useState(20);
    const [lowerUnit, setLowerUnit] = React.useState(tempUnit);
    const [timeThreshold, setTimeThreshold] = React.useState(30);
    const [timeUnit, setTimeUnit] = React.useState(thresholdConvert.unit);
    // const [deviceListOpen, setDeviceListOpen] = React.useState(false);
    const [intervalUnit, setIntervalUnit] = React.useState(intervalConvert.unit);

    const upLimitInput = React.useRef(null);
    const [upLimit, setUpLimit] = React.useState(10);
    const lowLimitInput = React.useRef(null);
    const [lowLimit, setLowLimit] = React.useState(2);
    const timeLimitInput = React.useRef(null);
    const [timeLimit, setTimeLimit] = React.useState(60);
    const intervalInput = React.useRef(null);
    const [interval, setInterval] = React.useState(10);

    const emailInput = React.useRef(null);
    const [email, setEmail] = React.useState('');

    const Navigate = useNavigate();

    useEffect(() => {
        setUpperUnit(tempUnit);
        setLowerUnit(tempUnit);
    }, [tempUnit]);

    const handleCloseSaveAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaveAlert(false);
    };

    const handleSaveAll = () => {
        let newConfig = {
            type: rangeType,
            upper_limit: convertToCelsius(upLimitInput.current.value, upperUnit),
            lower_limit: convertToCelsius(lowLimitInput.current.value, lowerUnit),
            time_limit: convertToSecond(timeLimitInput.current.value, timeUnit),
            interval: convertToSecond(intervalInput.current.value, intervalUnit),
            email: emailInput.current.value,
        };

        updateRealtime([record.deviceId], newConfig);
        setSaveAlert(true);

        // reload the page
        setTimeout(() => {
            Navigate(0);
        }, 1000);
    }

    return (
        <div className='modal__content'>
            <Snackbar open={saveAlert} autoHideDuration={6000} onClose={handleCloseSaveAlert}>
                <Alert onClose={handleCloseSaveAlert} severity='success'>
                    Save successfully!
                </Alert>
            </Snackbar>
            <form className='modal__form' id='alarmForm'>
                <div className='title'>{translation.configure}</div>
                <div className='modal__form__item__title'>{translation.failAlarm}</div>

                <hr className='hline'></hr>
                <div className='modal__form__item'>
                    <label className='modal__form__item__label'>{translation.excursionType}</label>
                    <div className='vline'></div>
                    <div className='rangebar__container__item excursion' style={{ width: '30%' }}>
                        <button id='continuous' type='button' className={rangeType === 'continuous' ? 'active' : ''} onClick={() => setRangeType('continuous')}>
                            {translation.singleEvent}
                        </button>
                    </div>
                    <div className='rangebar__container__item excursion' style={{ width: '30%' }}>
                        <button id='cumulative' type='button' className={rangeType === 'cumulative' ? 'active' : ''} onClick={() => setRangeType('cumulative')}>
                            {translation.cumulative}
                        </button>
                    </div>
                </div>
                <hr className='hline'></hr>
                <div className='modal__form__item'>
                    <label className='modal__form__item__label'>{translation.upperLimit}</label>
                    <div className='vline'></div>
                    <input key='upLimit' name='upLimit' className='modal__form__item__input alarm__config' type='text' placeholder={convertTemperature(8, upperUnit)} defaultValue={record.upperLimit} ref={upLimitInput} />
                    <button type='button' disabled={false} className={upperUnit === 'celsius' ? 'active config__button' : 'config__button'} onClick={() => setUpperUnit('celsius')}>
                        °C
                    </button>
                    <button type='button' disabled={true} className='config__button hidden'></button>
                    <button type='button' disabled={true} className='config__button hidden'></button>
                    <button type='button' disabled={false} className={upperUnit === 'fahrenheit' ? 'active config__button' : 'config__button'} onClick={() => setUpperUnit('fahrenheit')}>
                        °F
                    </button>
                </div>
                <hr className='hline'></hr>
                <div className='modal__form__item'>
                    <label className='modal__form__item__label'>{translation.lowerLimit}</label>
                    <div className='vline'></div>
                    <input key='lowLimit' name='lowLimit' className='modal__form__item__input alarm__config' type='text' placeholder={convertTemperature(2, lowerUnit)} defaultValue={record.lowerLimit} ref={lowLimitInput} />
                    <button type='button' disabled={false} className={lowerUnit === 'celsius' ? 'active config__button' : 'config__button'} onClick={() => setLowerUnit('celsius')}>
                        °C
                    </button>
                    <button type='button' disabled={true} className='config__button hidden'></button>
                    <button type='button' disabled={true} className='config__button hidden'></button>
                    <button type='button' disabled={false} className={lowerUnit === 'fahrenheit' ? 'active config__button' : 'config__button'} onClick={() => setLowerUnit('fahrenheit')}>
                        °F
                    </button>
                </div>
                <hr className='hline'></hr>
                <div className='modal__form__item'>
                    <label className='modal__form__item__label'>{translation.duration}</label>
                    <div className='vline'></div>
                    <input key='timeLimit' name='timeLimit' className='modal__form__item__input alarm__config' type='text' placeholder='10' defaultValue={thresholdConvert.value} ref={timeLimitInput} />
                    <button type='button' className={timeUnit === 'second' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('second')}>
                        {translation.S}
                    </button>
                    <button type='button' className={timeUnit === 'minute' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('minute')}>
                        {translation.M}
                    </button>
                    <button type='button' className={timeUnit === 'hour' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('hour')}>
                        {translation.H}
                    </button>
                    <button type='button' className={timeUnit === 'day' ? 'active config__button' : 'config__button'} onClick={() => setTimeUnit('day')}>
                        {translation.D}
                    </button>
                </div>
                <div className='modal__form__item__title'>{translation.dataLogging}</div>
                <hr className='hline'></hr>
                <div className='modal__form__item'>
                    <label className='modal__form__item__label'>{translation.loggingInterval}</label>
                    <div className='vline'></div>
                    <input key='interval' name='interval' disabled={true} className='modal__form__item__input alarm__config' type='text' placeholder='1' defaultValue={intervalConvert.value} ref={intervalInput} />
                    <button type='button' disabled={true} className={intervalUnit === 'second' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('second')}>
                        {translation.S}
                    </button>
                    <button type='button' disabled={true} className={intervalUnit === 'minute' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('minute')}>
                        {translation.M}
                    </button>
                    <button type='button' disabled={true} className={intervalUnit === 'hour' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('hour')}>
                        {translation.H}
                    </button>
                    <button type='button' disabled={true} className={intervalUnit === 'day' ? 'active config__button' : 'config__button'} onClick={() => setIntervalUnit('day')}>
                        {translation.D}
                    </button>
                </div>
                <div className='modal__form__item__title'>{translation.alarmNotification}</div>
                <hr className='hline'></hr>
                <div className='modal__form__item'>
                    <label className='modal__form__item__label'>{translation.email}</label>
                    <div className='vline'></div>
                    <input key='email' name='email' className='modal__form__item__input' type='text' placeholder='XXXXXX' defaultValue={record.email} ref={emailInput} />
                </div>
            </form>
            <div className='button__container' style={{marginTop: '2rem'}}>
                <button className='save__all' onClick={handleSaveAll}>
                        {translation.saveAll}
                </button>
            </div>
        </div>
    )
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50rem',
    bgcolor: '#FFFFFF',
    borderRadius: '1rem',
    boxShadow: '0px 4px 25px 0px #EAEE9E9',
    p: 0,
};

function Details(props) {
    const { record, searchStartTime, searchEndTime, translation, openDetail, setOpenDetail } = props;

    // const updateInterval = record.interval || 60;

    const tempUnit = useContext(temperatureContext);
    
    const [page, setPage] = React.useState(0);
    const [detail, setDetail] = React.useState([]);
    const [editName, setEditName] = React.useState(false);

    const nameInput = React.useRef(null);

    useEffect(() => {
        handleUpdateDevice();
    }, []);

    useEffect(() => {
        // const inverval = setInterval(() => {
            newUpdate();
        // }, updateInterval * 60000);
        // return () => clearInterval(inverval);
    }, [record]);

    const handleUpdateDevice = async () => {
        const now = dayjs();
        const timeOffset = now.utcOffset();
        const startTime = dayjs(searchStartTime).subtract(timeOffset, 'm').format('YYYY-MM-DDTHH:mm:ss+00:00');
        const endTime = dayjs(searchEndTime).subtract(timeOffset, 'm').format('YYYY-MM-DDTHH:mm:ss+00:00');
        getRealTimeById(record.deviceId, startTime, endTime).then((data) => {
            console.log(data);
            setDetail(data.detail.map(item =>
                {
                    return {
                        time: dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'),
                        temperature: convertTemperature(item.temperature, tempUnit),
                        gatewayId: item.gateway_id,
                    };
                }
            ));
        });
    }

    const newUpdate = async (item) => {
        const now = dayjs();
        const timeOffset = now.utcOffset();
        const startTime = dayjs(searchEndTime).subtract(timeOffset, 'm').subtract(10 , 'm').format('YYYY-MM-DDTHH:mm:ss+00:00');
        const endTime = dayjs(searchEndTime).subtract(timeOffset, 'm').format('YYYY-MM-DDTHH:mm:ss+00:00');
        getRealTimeById(record.deviceId, startTime, endTime).then((data) => {
            console.log(data);
            // remove duplicate
            data.detail = data.detail.filter((item) => item.time > detail[detail.length - 1].time);
            // combine old and new data into one array
            const newDetail = data.detail.map(item =>
                {
                    return {
                        time: dayjs(item.time).format('YYYY-MM-DD HH:mm:ss'),
                        temperature: convertTemperature(item.temperature, tempUnit),
                        gatewayId: item.gateway_id,
                    };
                }
            );
            setDetail([...detail,...newDetail]);
        });
    }

    const closeDetail = (e) => {
        e.stopPropagation();
        setOpenDetail(false);
    }

    const onEditName = () => {
        setEditName(true);
    }

    const onSaveName = () => {
        setEditName(false);
        record.name = nameInput.current.value;
        updateRealtime([record.deviceId], {'name': nameInput.current.value});
    }

    return (
        <Modal open={openDetail} onClose={closeDetail} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Box sx={modalStyle}>
                <div className='button__container'>
                    <button className='close' onClick={closeDetail}>
                        &times;
                    </button>
                </div>
                <div style={{padding: '0 3rem 0 3rem'}}>
                    <span style={{color: '#53545C', fontFamily: 'Roboto',fontSize: '1.5rem', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal'}}>
                        { editName ?
                            <input ref={nameInput} defaultValue={record.name} style={{ width: '200px', height: '1.6rem', fontSize: '16px', marginRight: '3px', border: 'solid 1px #53545C', borderRadius: '3rem', padding: '0rem 1rem' }}></input> :
                            record.name
                        }
                        { editName ? (
                            <Button variant='contained' sx={{ width: '100px', height: '25px' }} onClick={onSaveName}>
                                { translation.confirm }
                            </Button>
                        ) : (
                            <IconButton onClick={onEditName}>
                                <EditIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                        )}</span>
                    <span style={{color: '#53545C', float: 'right', fontFamily: 'Roboto', fontSize: '1.25rem', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal'}}>Last Refresh: {record.lastItem.time}</span>
                    <Rss  style={{width: '1rem', height: '1.3rem', float: 'right', marginRight: '0.6rem'}}/>
                </div>
                <div style={{padding: '0rem 3rem 0 3rem', display: 'flex', flexFlow: 'row nowrap'}}>
                    <div style={{width: '40%', paddingTop: '0.8rem'}}>
                        <p style={{margin: '0.75rem 0', color: '#53545C', fontFamily: 'Roboto', fontSize: '1.25rem', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal'}}>Gateway ID: {record.lastItem.gatewayId}</p>
                        <p style={{margin: '0.75rem 0', color: '#53545C', fontFamily: 'Roboto', fontSize: '1.25rem', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal'}}>Device ID: {record.deviceId}</p>
                    </div>
                    <div style={{width: '30%', paddingTop: '2rem'}}>
                        <p style={{margin: '0.2rem 0', color: '#53545C', fontFamily: 'Roboto', fontSize: '1rem', fontStyle: 'normal', fontWeight: 500, lineHeight: '1.25rem', float: 'right'}}>Upper Limit: {record.upperLimit}{tempUnit === 'fahrenheit' ? '°F' : '°C'}</p>
                        <p style={{margin: '0.2rem 0', color: '#53545C', fontFamily: 'Roboto', fontSize: '1rem', fontStyle: 'normal', fontWeight: 500, lineHeight: '1.25rem', float: 'right'}}>Lower Limit: {record.lowerLimit}{tempUnit === 'fahrenheit' ? '°F' : '°C'}</p>
                    </div>
                    <div style={{width: '30%'}}>
                        <span style={{color: '#53545C', fontFamily: 'Roboto', fontSize: '1.75rem', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', float: 'right'}}>{tempUnit === 'fahrenheit' ? '°F' : '°C'}</span>
                        <br/>
                        <span style={{color: '#53545C', fontFamily: 'Roboto', fontSize: '4rem', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', float: 'right'}}>{record.lastItem.temperature}</span>
                        
                    </div>
                </div>
                <div style={{ height: '30rem', display: 'flex', flexFlow: 'row nowrap' }}>
                    <button className='switch'>
                        <ArrowLeft onClick={() => setPage((page + 2) % 3)} />
                    </button>
                    <Box style={{ width: '100%', display: 'flex', margin: 1, flexFlow: 'row nowrap', alignItem: 'center', justifyContent: 'space-around' }}>
                        {
                            page === 0 ? <TempGraph record={record} detail={detail} translation={translation} /> :
                            page === 1 ? <TempList record={record} detail={detail} /> :
                            page === 2 ? <Config record={record} translation={translation.Edit}/> :
                            <></>
                        }
                    </Box>
                    <button className='switch'>
                        <ArrowRight onClick={() => setPage((page + 1) % 3)} />
                    </button>
                </div>
            </Box>
        </Modal>
    )
}

function Card(props) {
    const { record, searchStartTime, searchEndTime, translation } = props;

    const tempUnit = useContext(temperatureContext);

    const [openDetail, setOpenDetail] = React.useState(false);

    const color = record.normal ? '#BFE4FF' : '#FFDFDF';

    // const popup = () => {
    //     console.log(record);
    //     // setOpenDetail(!openDetail);
    //     setOpenDetail(true);
    // }

    return (
        <div className='card' onClick={() => setOpenDetail(true)} style={{ width: '13rem', height: '13rem', borderRadius: '15px', backgroundColor: '#FFFFFF', overflow: 'hidden', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
            <div style={{ width: '100%', color: '#53545C', fontSize: '0.875rem', fontFamily: 'Roboto', fontWeight: '600', fontStyle: 'normal', textAlign: 'center', margin: '0.25rem 0 0.25rem 0' }}>{record.name}</div>
            <div style={{ width: '7.75rem', height: '9.7rem', marginBottom: '0rem', boxShadow: '0px 0px 5px 0px #00000040 inset', borderRadius: '7rem', position: 'relative', backgroundImage: `linear-gradient(${color}33 ${50}%, ${color} ${50}%)`, overflow: 'hidden', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center' }}>
                <div style={{alignItems: 'center', display: 'flex', flow: 'row nowrap'}}>
                    <hr style={{ height: '1px', width: '30%', zIndex: '1', margin: '0px auto', backgroundColor: '#ADABAB', border: 'none' }} />
                    <span style={{color: '#757575'}}>{record.upperLimit}{tempUnit === 'fahrenheit' ? '°F' : '°C'}</span>
                    <hr style={{ height: '1px', width: '30%', zIndex: '1', margin: '0px auto', backgroundColor: '#ADABAB', border: 'none' }} />
                </div>
                <div style={{margin: '0rem 0 1rem 0'}}>
                    <div style={{ height: '0.5rem', fontSize: '1rem', textAlign: 'right', color: '#53545C', fontFamily: 'Roboto', fontWeight: 'bold', zIndex: '1', marginRight: '10%' }}>{tempUnit === 'fahrenheit' ? '°F' : '°C'}</div>
                    <div style={{ height: '3rem', fontSize: '3rem', textAlign: 'center', color: '#53545C', fontFamily: 'Roboto', fontWeight: '400', zIndex: '1', margin: '0px' }}>{record.lastItem.temperature}</div>
                </div>
                <div style={{alignItems: 'center', display: 'flex', flow: 'row nowrap'}}>
                    <hr style={{ height: '1px', width: '30%', zIndex: '1', margin: '0px auto', backgroundColor: '#ADABAB', border: 'none' }} />
                    <span style={{color: '#757575'}}>{record.lowerLimit}{tempUnit === 'fahrenheit' ? '°F' : '°C'}</span>
                    <hr style={{ height: '1px', width: '30%', zIndex: '1', margin: '0px auto', backgroundColor: '#ADABAB', border: 'none' }} />
                </div>
            </div>
            <div style={{width: '100%', alignItems: 'center', justifyContent: 'space-between', display: 'flex', flow: 'row nowrap'}}>
                <Rss  style={{width: '0.5rem', height: '0.5rem', margin: '0.5rem'}}/>
                <span style={{fontSize: '0.7rem', color: '#53545C', fontFamily: 'Roboto', fontWeight: '300'}}>Device ID: {record.deviceId}</span>
                <Settings style={{width: '0.7rem', height: '0.7rem', margin: '0.5rem'}}/>
            </div>
            { openDetail && <Details record={record} translation={translation} openDetail={openDetail} setOpenDetail={setOpenDetail} searchStartTime={searchStartTime} searchEndTime={searchEndTime} /> }
        </div>
    );
}


function createData(record, index, tempUnit) {
    const now = dayjs();
    // const timeOffset = now.utcOffset();
    const timeZone = dayjs(now).format('UTCZ');

    record.threshold.upper_limit = convertTemperature(record.threshold.upper_limit, tempUnit);
    record.threshold.lower_limit = convertTemperature(record.threshold.lower_limit, tempUnit);

    const lastItem = {
        temperature: record.temperature ? convertTemperature(record.temperature, tempUnit) : null,
        time: record.time ? dayjs(record.time).format('YYYY-MM-DD HH:mm:ss') : null,
        gatewayId: record.gateway_id ? record.gateway_id : null,
    }
    const normal = lastItem.temperature && lastItem.temperature >= record.threshold.lower_limit && lastItem.temperature <= record.threshold.upper_limit;

    // let details = [];
    // if (record.temperature) {
    //     detqails = record.temperature.map((temp, index) => {
    //         return {
    //             time: dayjs(record.time[index]).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm:ss'),
    //             temperature: temp,
    //             gatewayId: record.gateway_id[index],
    //         };
    //     });
    // }


    return { 
        num: index + 1,
        deviceId: record.device_id,
        name: record.name,
        lastItem,
        normal,
        timeZone,
        lowerLimit: record.threshold.lower_limit,
        upperLimit: record.threshold.upper_limit,
        timeLimit: record.threshold.time_limit,
        thresholdType: record.threshold.type,
        interval: record.interval,
        // details,
        email: record.meta_data.email,
    };
}


// consider using socket.io for real-time data
function RealTime(props) {

    const tempUnit = useContext(temperatureContext);

//   const [data, setData] = useState({});
    const [selectedDeviceId, setSelectedDeviceId] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState([]);
    const [startTime, setStartTime] = React.useState(dayjs().subtract(1, 'month'));
    const [endTime, setEndTime] = React.useState(dayjs());
    const [searchStartTime, setSearchStartTime] = React.useState(dayjs().subtract(1, 'month'));
    const [searchEndTime, setSearchEndTime] = React.useState(dayjs());

    const [searchString, setSearchString] = React.useState('');

    const [openAdd, setOpenAdd] = React.useState(false);
    const [openConfig, setOpenConfig] = React.useState(false);

    const [records, setRecords] = React.useState([]);

    const [saveAlert, setSaveAlert] = React.useState(false);
    const [alertState, setAlertState] = React.useState({ severity: 'error', message: '' });

    const { translation } = props;

    useEffect(() => {
        handleUpdate();
        const inverval = setInterval(() => {
            handleUpdate();
        }, 60000);
        return () => clearInterval(inverval);
    }, [searchEndTime]);

    const handleUpdate = async () => {
        const now = dayjs();
        const timeOffset = now.utcOffset();
        const startTime = dayjs(searchStartTime).subtract(timeOffset, 'm').format('YYYY-MM-DDTHH:mm:ss+00:00');
        const endTime = dayjs(searchEndTime).subtract(timeOffset, 'm').format('YYYY-MM-DDTHH:mm:ss+00:00');
        getRealTime(startTime, endTime).then((data) => {
            console.log(data);
            data = data.records.map((record, index) => {
                return createData(record, index, tempUnit);
            });
            setRecords(data);
            // data.records.sort((a, b) => {
            //     if (!a.create_time) {
            //         return 1;
            //     } else if (!b.create_time) {
            //         return -1;
            //     }
            //     return dayjs(b.create_time).unix() - dayjs(a.create_time).unix();
            // });
            // data = data.records.map((record, index) => {
            //     // return createData(record.record_id, record.create_time, record.length, record.region, record.company, record.status, record.trackNum, record.deviceId)
            //     // solve time zone problem
            //     return createData(record, index)
            //     // return createData(index + 1, `${record.device_id}-${record.index}`, dayjs(record.create_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'), dayjs(record.start_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'), dayjs(record.end_time).add(timeOffset, 'm').format('YYYY-MM-DD HH:mm'), record.number_of_records, record.interval, dayjs(now).format('UTCZ'), record.meta_data.shipper_name, record.is_normal, record.meta_data.shipment_number, record.meta_data.note, record.device_id, record.index, record.threshold.lower_limit, record.threshold.upper_limit, record.threshold.time_limit, record.total_minute, record.threshold.type);
            // });
            // data = filterRecords(data, searchString);
            // setRecordCount(data.length);
            // setPageCount(Math.ceil(data.length / 10));
            // setRecords(data);
        })
    };

    const handleSearch = () => {
        // setSearch(event.target.value);
        setSearchStartTime(dayjs(startTime));
        setSearchEndTime(dayjs(endTime));
        // setFilter(true);
        // setPage(1);
    };

    const handleAddUpdate = () => {
        setEndTime(dayjs());
        setSearchEndTime(dayjs());
        // handleUpdate();
    };

    const handleCloseSaveAlert = (event, reason) => {
        // reload page for now
        // think about only update state
        // Navigate(0);
        if (reason === 'clickaway') {
            return;
        }
        setSaveAlert(false);
    };


    return (
        <div style={{ width: '95%', minHeight: '100vh', paddingBottom: '20px', margin: '32px auto 0 auto' }}>
            <Snackbar open={saveAlert} autoHideDuration={6000} onClose={handleCloseSaveAlert}>
                <Alert onClose={handleCloseSaveAlert} severity={alertState.severity}>
                    {alertState.message}
                </Alert>
            </Snackbar>

            <div style={{ height: '100px', padding: '0px 30px', display: 'flex', borderRadius: '16px', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: '#FFFFFF' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={translation.startTime}
                        value={startTime}
                        onChange={(newTime) => {
                            setStartTime(newTime);
                        }}
                        renderInput={(params) => <TextField {...params} size='small' sx={{ width: '175px', marginLeft: '0px', marginRight: '0px' }} />}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={translation.endTime}
                        value={endTime}
                        onChange={(newTime) => {
                            setEndTime(newTime);
                        }}
                        renderInput={(params) => <TextField {...params} size='small' sx={{ width: '175px', marginLeft: '0px', marginRight: '47px' }} />}
                    />
                </LocalizationProvider>
                <TextField label='' variant='outlined' InputProps={{ sx: { height: 40 } }} sx={{ width: '475px' }} onChange={(e) => setSearchString(e.target.value)} />
                <Button sx={{ marginRight: 'auto' }} onClick={handleSearch}>
                    {translation.search}
                </Button>
                <div style={{ height: '64px', borderLeft: '1px solid #D9D9D9', margin: '0px 40px' }}></div>

                <Button variant='contained' sx={{ width: '140px', minWidth: '80px', margin: '5px' }} onClick={() => setOpenAdd(true)}>
                    <AddIcon className='svgIcon' style={{ marginRight: '10px' }} />
                    {translation.add}
                </Button>
                <Button variant='contained' sx={{ width: '140px', minWidth: '80px', margin: '5px' }} onClick={() => setOpenConfig(true)}>
                    <EditIcon sx={{ marginRight: '10px' }} />
                    {translation.edit}
                </Button>
                {/* <Button variant="contained" disabled sx={{width: '140px', margin: '5px'}} onClick={createPDF}><PictureAsPdfIcon sx={{marginRight: '10px'}}/>{translation.export}</Button> */}

                {/* Edit Popup */}
                <Add openAdd={openAdd} setOpenAdd={setOpenAdd} setSaveAlert={setSaveAlert} setAlertState={setAlertState} translation={translation.Add} handleAddUpdate={handleAddUpdate} realtime={true} />
                {/* <Edit openConfig={openConfig} setOpenConfig={setOpenConfig} setSaveAlert={setSaveAlert} setAlertState={setAlertState} selectedDeviceId={selectedDeviceId} selectedIndex={selectedIndex} translation={translation.Edit}
                    handleUpdate={handleUpdate} 
                /> */}
            </div>

            <div style={{ display: 'flex', flexFlow: 'row wrap', gap: '1rem', justifyContent: 'flex-start', marginTop: '1rem' }}>
                {records.map((record, index) => (
                    <Card key={record.deviceId} record={record} searchStartTime={searchStartTime} searchEndTime={searchEndTime} translation={translation} />
                ))}
            </div>

            
        </div>
    );
}

export default RealTime;